<template>
  <div class="search-box">
    <van-search
      v-model="searchValue"
      show-action
      placeholder="请输入搜索关键词"
      :maxlength="30"
      @search="onSearch"
      @clear="onSearch"
    >
      <template #action>
        <a @click="onClickFilter">
          <i class="iconfont iconfilter" />
        </a>
      </template>
    </van-search>

    <van-popup v-model="isShowFilter" position="top" style="top: 64px;">
      <div class="popup-tags">
        <van-tabs
          v-if="isShowFileType"
          v-model="activeTab"
          class="talk-skill-tab"
          background="#ffffff"
          color="#2F7DCD"
          title-active-color="#467DB9"
          title-inactive-color="#000000"
          :ellipsis="false"
          @click="onTabActive"
        >
          <van-tab
            v-for="(item, index) in materialTypeList"
            :key="item"
            :name="index"
            :title="item"
          >
          </van-tab>
        </van-tabs>

        <div
          v-for="category in categoryList"
          :key="category.groupId"
          class="tags-box"
        >
          <div class="title">{{ category.groupName }}</div>
          <div class="first-tags-box">
            <span
              class="tag-title"
              :class="checkGroupId === category.groupId ? 'tag-active' : ''"
              @click="onChangeGroup(category.groupId, category.childPath)"
            >
              全部
            </span>
            <span
              v-for="child in category.childPath"
              :key="child.groupId"
              class="tag-title"
              :class="checkGroupId === child.groupId ? 'tag-active' : ''"
              @click="onChangeGroup(child.groupId)"
            >
              {{ child.groupName }}
            </span>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      searchValue: '',
      activeTab: 0,
      isShowAction: true,
      isShowFilter: false,
      isShowFileType: true,
      materialTypeList: ['图片', '视频', '文件', '图文', '小程序'],
      checkGroupId: ''
    }
  },
  computed: {
    ...mapState({
      categoryList: state => state.material.categoryList
    })
  },

  watch: {
    activeTab(val, oVal) {
      // 当在图片、视频、文件之类切换时不需要再调分组接口
      // 只有在图文和其他类型切换时，才需要重新请求接口
      if (val !== oVal) {
        if (val === 3) {
          this.$store.dispatch('material/fetchMaterialCategoryList', {
            catId: 22000
          })
        }
        else {
          this.$store.dispatch('material/fetchMaterialCategoryList', {
            catId: val === 4 ? 9 : val + 1
          })
        }
      }
    }
  },

  created() {
    this.$store.dispatch('material/fetchMaterialCategoryList', { catId: 1 })
  },

  methods: {
    onClickFilter() {
      this.isShowFilter = !this.isShowFilter
    },

    onSearch() {
      this.$store.commit('material/setQueryParams', { title: this.searchValue, pageIndex: 1 })
      this.$store.commit('material/setMaterialList', [])
      this.executeSearch()
    },

    onTabActive(index) {
      this.$store.commit('material/setQueryParams', {
        type: index === 4 ? 9 : index + 1,
        pageIndex: 1
      })
      this.$store.commit('material/setMaterialList', [])
      this.executeSearch()
    },
    onChangeGroup(groupId, childPath = []) {
      let groupList = []
      let params = {}
      if (groupId !== this.checkGroupId) {
        this.checkGroupId = groupId
        const childList = childPath.map(child => child.groupId)
        groupList = [groupId, ...childList]
      }
      else {
        this.checkGroupId = ''
      }

      if (this.activeTab === 3) {
        params = { groupIdList: groupList, pageIndex: 1 }
      }
      else {
        params = { groupList, pageIndex: 1 }
      }

      this.$store.commit('material/setQueryParams', params)
      this.$store.commit('material/setMaterialList', [])
      this.executeSearch()
    },

    executeSearch() {
      if (this.activeTab === 3) {
        this.$store.dispatch('material/fetchLinkMaterialList')
      }
      else {
        this.$store.dispatch('material/fetchMaterialList')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.search-box {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 3000;
  .van-search {
    padding: 12px 16px;
    z-index: 3000;
    position: relative;
    .iconfont {
      font-size: 30px;
      color: #999;
    }
    .van-search__content {
      background: #f5f5f5;
      border-radius: 4px;
      .van-cell {
        padding: 7px 2px 7px 0;
        .van-icon {
          color: #bfbfbf;
        }
        .van-field__body {
          padding-right: 10px;
        }
      }
    }
    .van-search__action {
      color: #999999;
      font-size: 14px;
      padding-left: 12px;
      &:active {
        background: none;
      }
    }

    /deep/ .van-field__clear {
      width: 30px;
      text-align: center;
      z-index: 999;
    }
  }

  .popup-tags {
    /deep/ .van-sticky--fixed {
      top: 69px !important;
    }
    width: 100%;
    height: 260px;
    overflow: scroll;
    .tags-box {
      padding: 0 16px 14px;
      box-sizing: border-box;
      .title {
        font-size: 14px;
        color: #333333;
        padding: 10px 0 4px 0;
        font-weight: 500;
      }
      .first-tags-box {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        .tag-title {
          margin-top: 12px;
          margin-right: 8px;
          font-size: 12px;
          color: #b1b1b1;
          padding: 3px 16px;
          background: #f5f5f5;
          border: 1px solid #f5f5f5;
          border-radius: 19px;
        }
        .tag-active {
          color: #287de1 !important;
          background: rgba(40, 125, 225, 0.2) !important;
          border: 1px solid #287de1 !important;
        }
      }
    }
  }
}
</style>
