<template>
  <div class="material-index">
    <search-bar />
    <div class="content-box">
      <base-material v-if="inited" />
    </div>
    <buttom-bar />
  </div>
</template>

<script>
import './constant'
import { mapGetters } from 'vuex'
// import { getUser } from '@/scripts/userAuth'
import wxSDK from '@/scripts/wxsdk'
import store from '@/store/index'

import SearchBar from './components/search-bar.vue'
import ButtomBar from './components/buttom-bar.vue'
import BaseMaterial from './components/base-material.vue'

export default {
  name: 'Material',
  components: {
    SearchBar,
    ButtomBar,
    BaseMaterial
  },
  data() {
    return {
      inited: false
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'userAuth/getUsersCurrentInfo'
    })
  },
  watch: {
    async '$route'(_to, _from) {
      if (!this.inited) {
        await this.init()
      }
    }
  },

  async created() {
    await this.init()
  },

  methods: {
    async init() {
      // await getUser()
      const auth_type = global.APP_CONFIG.auth_type
      console.log(12345567)
      if (auth_type === 'webAuthLogin' || auth_type === 'scanCodeAuthLogin') {
        await wxSDK.config(
          [],
          [
            'getContext',
            'getCurExternalContact',
            'sendChatMessage',
            'shareAppMessage',
            'getCurExternalChat'
          ]
        )
      }
      else if (auth_type === 'customAuthLogin') {
        await this.customAuthLogin()
      }
      this.inited = true
    },
    async customAuthLogin() {
      // 此处自定义鉴权和sdk接入
      const authorizeInfo = {
      }
      const usersCurrentInfo = {
      }
      store.commit('userAuth/authorizeInfo', authorizeInfo)
      store.commit('userAuth/usersCurrentInfo', usersCurrentInfo)
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
  font-size: 16px;
  font-family: DFPKingGothicGB-Medium, DFPKingGothicGB;
  font-weight: 500;
  color: #000000;
  padding: 12px 16px;
  box-sizing: border-box;
}
.material-index {
  height: 100vh;
  .content-box {
    height: calc(100vh - 114px);
    overflow: auto;
    margin-top: 64px;
  }
}
</style>
