<template>
  <div class="buttom-bar">
    <van-tabbar ref="tabbarInfo" v-model="activeIndex">
      <van-tabbar-item
        v-for="item in tabBarList"
        :key="item.id"
        :name="item.id"
        :title="item.title"
        :icon="item.icon"
      >
        <span>{{ item.title }}</span>
        <template #icon="props">
          <i
            style="font-size: 20px"
            class="iconfont"
            :class="props.active ? item.icon2 : item.icon1"
          />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: 0,
      tabBarList: [
        {
          title: '基础素材',
          url: 'basic',
          id: 16,
          type: 6,
          icon1: 'iconjuxing',
          icon2: 'iconjuxing11',
          tabType: 'MATERIAL'
        }
      ]
    }
  }
}
</script>
