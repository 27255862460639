<template>
  <div class="base-material">
    <van-list
      :finished="listFinished"
      finished-text=""
      offset="50"
      class="material-list"
      @load="onLoadMore"
    >
      <div
        v-for="material in materialList"
        :key="material.id"
        class="material-item"
        :class="{full: ['FileMaterial', 'LinkMaterial'].includes(cmpMaterialType)}"
      >
        <component
          :is="cmpMaterialType"
          :material="material"
          :type="queryParams.type"
          @on-share="onShareMaterial"
        />
      </div>
    </van-list>
    <div v-if="materialList.length === 0" class="frame-144">
      <div class="frame-144-img">
        <img src="@/assets/img/frame_144.png" alt="" />
      </div>
      <div class="frame-title">
        暂无素材
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import { mapState } from 'vuex'
import wxSDK from '@/scripts/wxsdk'
import { getMaterialId } from '@/api/modules/common'
import { pushSharePoint } from '@/api/modules/material'
import { mapGetters } from 'vuex'
import '../constant'

import ImageMaterial from './image-material.vue'
import VideoMaterial from './video-material.vue'
import FileMaterial from './file-material.vue'
import LinkMaterial from './link-material.vue'

export default {
  components: {
    ImageMaterial,
    VideoMaterial,
    FileMaterial,
    LinkMaterial
  },
  props: {
    materialType: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      finishedText: '',
      listLoading: false
    }
  },
  computed: {
    ...mapState({
      materialList: state => state.material.materialList,
      materialCount: state => state.material.materialCount,
      queryParams: state => state.material.queryParams,
      isLoading: state => state.material.isLoading,
      listFinished: state => state.material.isFinished
    }),
    ...mapGetters({
      userInfo: 'userAuth/getUsersCurrentInfo'
    }),
    // 根本素材类型显示不同类型组件
    cmpMaterialType() {
      switch (this.queryParams.type - 1) {
        case 1:
          return 'VideoMaterial'
        case 2:
          return 'FileMaterial'
        case 3:
          return 'LinkMaterial'
        default:
          return 'ImageMaterial'
      }
    }
  },
  watch: {
    isLoading(val) {
      this.listLoading = val
    }
  },

  async created() {
    this.$store.dispatch('material/fetchMaterialList')
  },

  methods: {
    /**
     * 发送素材+埋点接口上报
     */
    async onShareMaterial(material) {
      const typeList = ['image', 'video', 'file', 'news', 'miniprogram']
      const type = this.queryParams.type === 9 ? 'miniprogram' : typeList[this.queryParams.type - 1]
      const pointParam = await this.creatPointParam()
      if (type === 'news') {
        this.sendNews(type, material, pointParam)
      }
      else if (type === 'miniprogram') {
        this.sendMiniprogram(type, material, pointParam)
      }
      else if (type === 'file') {
        this.sendFile(type, material, pointParam)
      }
      else {
        this.sendDefault(type, material, pointParam)
      }
    },
    // 生成埋点公共参数
    async creatPointParam() {
      const statisticsId = uuidv4()
      const sendChannel = await wxSDK.getContext()
      let customerId = ''
      if (sendChannel === 'single_chat_tools') {
        customerId = await wxSDK.getCurExternalContact()
      }
      else if (sendChannel === 'group_chat_tools') {
        customerId = await wxSDK.getCurExternalChat()
      }
      const pointParam = { customerId, sendChannel, statisticsId }
      return pointParam
    },
    async sendNews(type, material, pointParam) {
      const params = {
        link: '' + material.detailUrl + '/' + pointParam.statisticsId,
        title: '' + material.title,
        desc: '' + material.description,
        imgUrl: '' + material.simpleImgUrl
      }
      const res = await wxSDK.sendChatMessage(type, params)

      if (res === true) {
        await pushSharePoint({
          ...pointParam,
          materialId: material.contentId,
          materialName: material.title,
          materialType: 4,
          operateType: 1
        })
      }
    },
    async sendCosNews(type, material, id, pointParam) {
      const pathName = type === 'image' ? 'graphicDetail' : type === 'video' ? 'videoDetail' : ''
      const detailUrl = `${location.protocol}//${location.host}/vSearch/${global.APP_CONFIG.tenantId}/${pathName}/${material.id}?id=${material.id}&notInQw=1`
      const params = {
        link: '' + detailUrl + '/' + pointParam.statisticsId,
        title: '' + material.title,
        desc: '',
        imgUrl: '' + material.cosPath || material.videoCoverUrl
      }
      const res = await wxSDK.sendChatMessage('news', params)

      if (res === true) {
        await pushSharePoint({
          ...pointParam,
          materialId: material.contentId,
          materialName: material.title,
          materialType: 4,
          operateType: 1
        })
      }
    },
    // 发送小程序
    async sendMiniprogram(type, material) {
      const params = {
        appid: material.appId,
        title: material.title,
        imgUrl: material.cosPath,
        page: material.appUrl
      }
      await wxSDK.sendChatMessage(type, params)
    },
    async sendDefault(type, material, pointParam) {
      const that = this
      const data = {
        appId: global.APP_CONFIG.appId,
        cosUrl: material.cosPath,
        tenantId: global.APP_CONFIG.tenantId,
        type
      }
      getMaterialId(data).then(async(resolve) => {
        if (resolve.code === 0) {
          const { id } = resolve.data

          if (resolve.data.type === 'cos') {
            that.sendCosNews(type, material, id, pointParam)
          }
          else {
            const params = {
              mediaid: id
            }
            const res = await wxSDK.sendChatMessage(type, params)
            if (res === true) {
              await pushSharePoint({
                ...pointParam,
                materialId: material.id,
                materialType: material.type,
                operateType: 1,
                materialName: material.title
              })
            }
          }
        }
      })
    },
    // 发送文件
    async sendFile(type, material) {
      const data = {
        appId: global.APP_CONFIG.appId,
        cosUrl: material.cosPath,
        tenantId: global.APP_CONFIG.tenantId,
        type
      }
      getMaterialId(data).then(async(resolve) => {
        if (resolve.code === 0) {
          const { id } = resolve.data
          const params = {
            mediaid: id
          }
          await wxSDK.sendChatMessage(type, params)
        }
      })
    },
    /**
     * 加载素材列表
     */
    onLoadMore() {
      if (this.isLoading) return
      if (this.materialList.length < this.materialCount) {
        this.$store.commit('material/setQueryParams', {
          pageIndex: this.queryParams.pageIndex + 1
        })
        if (this.queryParams.type === 4) {
          this.$store.dispatch('material/fetchLinkMaterialList')
        }
        else {
          this.$store.dispatch('material/fetchMaterialList')
        }
      }
      else {
        this.finishedText = '没有更多了'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.material-list {
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  .material-item {
    width: 50%;
    &.full {
      width: 100%;
    }
  }
}
.frame-144 {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -62px;
  transform: translate(-50%, -50%);
  z-index: 99;
  width: 100%;
  .frame-144-img {
    width: 189px;
    height: 184px;
    margin: 0 auto;
  }
  .frame-title {
    color: #999999;
    font-size: 16px;
  }
}
</style>
