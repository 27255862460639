<template>
  <div class="search-box">
    <van-search
      v-model="searchValue"
      :readonly="isReadOnly"
      :show-action="isShowAction"
      placeholder="请输入搜索关键词"
      @search="onSearch"
      @clear="onSearch"
      @blur="onSearch"
    >
      <template #action>
        <a @click="onClickFilter">
          <i class="iconfont iconfilter" />
        </a>
      </template>
    </van-search>

    <van-popup v-model="isShowFilter" position="top" style="top: 64px;">
      <div class="popup-tags">
        <div
          class="tags-box"
        >
          <div class="title">问卷场景分类</div>
          <div class="first-tags-box">
            <span
              v-for="item in dataList"
              :key="item.id"
              class="tag-title"
              :class="checkDictCode === item.dictCode ? 'tag-active' : ''"
              @click="onChang(item.dictCode)"
            >
              {{ item.dictCodeName }}
            </span>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { queryDictByCode } from '@/api/modules/questionnaire'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      searchValue: '',
      isReadOnly: false,
      isShowAction: true,
      isShowFilter: false,
      dataList: [],
      checkDictCode: ''
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'userAuth/getUsersCurrentInfo'
    })
  },
  mounted() {
    // 获取问卷场景分类
    this.getDic(1001)
  },

  methods: {
    async getDic(dictCodeType) {
      const res = await queryDictByCode({ dictCodeType })
      this.dataList = res.data
    },
    onClickFilter() {
      this.isShowFilter = !this.isShowFilter
    },
    onSearch() {
      this.onChang(this.checkDictCode)
    },
    onChang(dictCode) {
      if (dictCode !== this.checkDictCode) {
        this.checkDictCode = dictCode
      }
      this.$emit('tabChange', dictCode, this.searchValue)
      this.isShowFilter = false
    }
  }
}
</script>

<style lang="less" scoped>
.search-box {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 3000;
  .van-search {
    padding: 12px 16px;
    z-index: 3000;
    position: relative;
    .iconfont {
      font-size: 30px;
      color: #999;
    }
    .van-search__content {
      background: #f5f5f5;
      border-radius: 4px;
      .van-cell {
        padding: 7px 2px 7px 0;
        .van-icon {
          color: #bfbfbf;
        }
        .van-field__body {
          padding-right: 10px;
        }
      }
    }
    .van-search__action {
      color: #999999;
      font-size: 14px;
      padding-left: 12px;
      &:active {
        background: none;
      }
    }
  }

  .popup-tags {
    /deep/ .van-sticky--fixed {
      top: 69px !important;
    }
    width: 100%;
    height: 260px;
    overflow: scroll;
    .tags-box {
      padding: 0 16px 14px;
      box-sizing: border-box;
      .title {
        font-size: 14px;
        color: #333333;
        padding: 10px 0 4px 0;
        font-weight: 500;
      }
      .first-tags-box {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        .tag-title {
          margin-top: 12px;
          margin-right: 8px;
          font-size: 12px;
          color: #b1b1b1;
          padding: 3px 16px;
          background: #f5f5f5;
          border: 1px solid #f5f5f5;
          border-radius: 19px;
        }
        .tag-active {
          color: #287de1 !important;
          background: rgba(40, 125, 225, 0.2) !important;
          border: 1px solid #287de1 !important;
        }
      }
    }
  }
}
</style>
