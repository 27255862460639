<template>
  <div class="material-index">
    <search-bar v-if="!currEntry" @tabChange="tabChange"/>
    <div v-if="!currEntry" class="content-box">
      <QuestionnaireList v-if="inited" :dict-code="dictCode" :search-value="searchValue" :customer-id="customerId"/>
    </div>
    <div v-if="currEntry" class="noContent">请从<b>客户</b>会话的工具栏进入</div>
  </div>
</template>
<script>
import SearchBar from './components/search-bar.vue'
import QuestionnaireList from './components/questionnaireList.vue'
import wxSDK from '@/scripts/wxsdk'
import store from '@/store/index'
import { Toast } from 'vant'

export default {
  name: 'Questionnaire',
  components: {
    SearchBar,
    QuestionnaireList
  },
  data() {
    return {
      inited: false,
      dictCode: '', // 已选择的问卷场景分类
      searchValue: '', // 关键词
      currEntry: false,
      customerId: ''
    }
  },
  async created() {
    await this.init()
  },
  methods: {
    async init() {
      // this.inited = true
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0
      })
      await wxSDK.config(
        [],
        [
          'getContext',
          'getCurExternalContact',
          'sendChatMessage'
        ]
      )
      // 判断入口
      const entry = await wxSDK.getContext()
      if (entry === 'group_chat_tools') {
        this.currEntry = true
      }
      else {
        this.currEntry = false
        const customerId = await wxSDK.getCurExternalContact()
        store.commit('userAuth/customerInfo', customerId)
        this.customerId = customerId
        console.log('customerId', customerId)
        this.inited = true
      }
      Toast.clear()
    },
    tabChange(code, val) {
      this.dictCode = code
      this.searchValue = val
    }
  }
}
</script>

<style lang="less" scoped>
.material-index {
  height: 100vh;
  .content-box {
    height: calc(100vh - 64px);
    overflow: auto;
    margin-top: 64px;
  }
}
.noContent{
  font-size: 23px;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
</style>
