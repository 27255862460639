import { get, post } from '@/api/index'
const prefix = '/satisfaction-management'
const prefix2 = '/satisfaction-use'

// 获取问卷场景分类
export async function queryDictByCode(params) {
  return get(`${prefix}/dict/query/by/code/type`, params)
}
// 根据企微顾问id查询场景问卷列表
export async function getWtchatSatisfaction(params) {
  return post(`${prefix2}/v1/corporate_wechat_publish/satisfaction`, params)
}
// 投放问卷
export async function corporatePublish(params) {
  return post(`${prefix2}/v1/corporate_wechat_publish/publish`, params)
}
