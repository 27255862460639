<template>
  <div class="item">
    <div class="item-box">
      <div class="card">
        <div class="card-top file">
          <div v-if="material.title" class="card-l">
            <van-image
              :src="jcscFile"
              width="32"
              height="32"
              fit="cover"
              radius="4"
            />
          </div>
          <div class="two">
            <div class="title">{{ material.title }}</div>
          </div>
          <div
            class="box1 activeColor"
            @click.stop="$emit('on-share', material)"
          >
            <i style="font-size: 24px" class="iconfont iconfasong"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const jcscFile = require('@/assets/img/jcscFile.png')

export default {
  props: {
    material: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      jcscFile
    }
  }
}
</script>
<style lang="less" scoped>
@import '@/assets/less/common.less';
.item {
  margin-top: 16px;
  width: 100%;
  .item-box {
    width: 100%;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
    .card {
      padding: 0 4.267vw;
      /deep/ .van-checkbox {
        border-bottom: 1px solid #e8e8e8;
      }
      /deep/ .van-checkbox__label {
        width: 92%;
      }
      .card-top {
        display: flex;
        align-items: center;
        overflow: hidden;
        padding: 16px 0;
        box-sizing: border-box;
        justify-content: space-between;
        .card-l {
          width: 10%;
          margin: 0 2% 0 1%;
        }
        .two {
          width: 75%;
          .title {
            font-size: 14px !important;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        .box1 {
          width: 20%;
          padding-right: 10px;
          color: #999999;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .title {
            font-size: 14px;
          }
          &.activeColor {
            color: #287de1;
          }
        }
      }
    }
  }
  .bottomBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 43px;
    .box1 {
      flex: 1;
      color: #999999;
      display: flex;
      align-items: center;
      justify-content: center;
      .title {
        font-size: 14px;
      }
      &.activeColor {
        color: #287de1;
      }
    }
    .box2 {
      width: 2%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .box2-border {
        height: 12px;
        width: 1px;
        background: #e8e8e8;
        border-radius: 1px;
      }
    }
  }
}
</style>
