<template>
  <div class="cont">
    <div class="list">
      <van-pull-refresh
        v-model="loading"
        class="vanList"
        @refresh="resetData"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div
            v-for="(item, index) in list"
            :key="index"
            class="list_cont"
          >
            <div class="img_l">
              <img class="lt-icon" src="@/assets/img/backup.png">
            </div>
            <div class="rt-cont">
              <div class="rt-center">
                <div class="rt-title">{{ item.sceneName }}</div>
                <div class="rt-text">{{ item.sceneCategoryName }}</div>
              </div>
              <div class="rt-right">
                <div class="rt-title">题目数量：{{item.questionCount}}</div>
                <i class="activeColor iconfont iconfasong" @click="toSend(item)"></i>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import '@vant/touch-emulator'
import { mapGetters } from 'vuex'
import { getWtchatSatisfaction, corporatePublish } from '@/api/modules/questionnaire'
import { Toast } from 'vant'
import wxSDK from '@/scripts/wxsdk'

export default {
  props: {
    dictCode: {
      type: String,
      default: ''
    },
    searchValue: {
      type: String,
      default: ''
    },
    customerId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: true,
      pages: {
        pageIndex: 1,
        pageSize: 10
      },
      totalCount: 0
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'userAuth/getUsersCurrentInfo'
    })
  },
  watch: {
    dictCode: {
      handler() {
        this.pages.pageIndex = 1
        this.getList()
      }
    },
    searchValue: {
      handler() {
        this.pages.pageIndex = 1
        this.getList()
      }
    }
  },
  async created() {
    this.getList()
  },
  mounted() {
  },
  methods: {
    setListData(listData) {
      this.list = listData
    },
    resetData() {
      this.isLoading = false
      this.resetting()
    },
    resetting() {
      this.pages.pageIndex = 1
      this.getList()
    },
    getList() {
      this.pages = {
        sceneCategoryCode: this.dictCode,
        userId: this.userInfo.qwId,
        sceneName: this.searchValue,
        pageIndex: this.pages.pageIndex,
        pageSize: this.pages.pageSize
      }
      getWtchatSatisfaction(this.pages).then((res) => {
        this.loading = false
        const data = res.data
        if (this.pages.pageIndex === 1) {
          this.list = data.dataList
        }
        else {
          this.list = this.list.concat(data.dataList)
        }
        this.totalCount = data.page.totalCount
        if (this.list.length >= this.totalCount) {
          this.finished = true
        }
        else {
          this.finished = false
        }
      })
    },
    onLoad() {
      if (this.list.length < this.totalCount && this.totalCount !== 0) {
        this.pages.pageIndex++
        this.getList()
      }
    },
    // 发送问卷
    async toSend(item) {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0
      })
      const params = {
        sceneCode: item.sceneCode,
        userId: this.customerId,
        qwId: this.userInfo.qwId
      }
      console.log('params==', params)
      const { code, data } = await corporatePublish(params)
      if (code === 0) {
        Toast.clear()
        const params = {
          link: '' + data.questionnaireShortUrl,
          title: '' + item.sceneName,
          desc: '' + item.sceneCategoryName,
          imgUrl: 'https://static-gm-1312970978.cos.ap-shanghai.myqcloud.com/scrm-h5/static/img/2291670582006_.pic_hd.jpg'
        }
        console.log('params==', params)
        wxSDK.sendChatMessage('news', params)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.van-search {
  border: 1px solid #808080;
  padding: 0;
}
.van-search__content {
  background-color: #fff;
}
.van-cell {
  padding: 0;
}

.body {
  padding: 0;
  overflow-y: auto;
  margin-top:64px;
}

.search {
  height: 32px;
  background: #ffffff;
  border: 1px solid #808080;
}

.lt-icon {
  width: 15px;
  height: 15px;
  margin-top: 4px;
}

.list{
  padding: 16px;
  .img_l {
    margin-top: 20px;
  }
  .img_l img {
    padding-top: 4px;
    width: 16px;
  }

  .list_cont {
    display: flex;
  }

  .rt-cont {
    width: 100%;
    margin-left: 10px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    .rt-center{
      flex:1;
      width: 0;
    }
    .rt-text {
      flex: 1;
      font-size: 12px;
      font-weight: 300;
      color: #333333;
      margin-top: 4px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .activeColor {
      color: #287de1;
      font-size: 24px;
    }
    .rt-title {
      font-size: 14px;
      font-weight: 500;
      color: #000000;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .rt-right{
      width:120px;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
