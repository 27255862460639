<template>
  <div class="item">
    <div class="item-box">
      <div v-if="material.cosPath" class="bfBtn" @click="toPup">
        <!-- 播放按钮 -->
        <van-image
          :src="videoBF | formatImgUrl(32, 32)"
          width="32"
          height="32"
          fit="cover"
          radius="4"
        ></van-image>
      </div>
      <div v-if="material.cosPath" class="card-l">
        <van-image
          :src="material.videoCoverUrl"
          width="100%"
          height="118"
          radius="4"
        ></van-image>
      </div>
      <div class="bom">
        <div class="two">
          <div class="title">{{ titleCom }}</div>
        </div>
        <div class="box1 activeColor" @click.stop="$emit('on-share', material)">
          <i style="font-size: 24px" class="iconfont iconfasong"></i>
        </div>
      </div>
      <div class="tag">
        <van-tag type="warning" class="tag2">已发送:{{ sendTimes2 }}</van-tag>
      </div>
    </div>
    <van-popup v-model="showPopup" @close="closePup">
      <div style="width:300px;height:200px;overflow: hidden;">
        <video
          id="video"
          width="100%"
          height="100%"
          :src="material.cosPath"
          controls="controls"
          :poster="material.videoCoverUrl"
        ></video>
      </div>
    </van-popup>
  </div>
</template>
<script>
const videoBF = require('@/assets/img/videoBF.png')

export default {
  filters: {
    formatImgUrl(value, width = 32, height = 32) {
      const num = 8
      return value
        ? `${value}?imageMogr2/crop/${width * num}x${height *
            num}/gravity/center`
        : ''
    }
  },
  props: {
    material: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      videoBF,
      // sp,
      checked: false,
      isCommon: false,
      showPopup: false
    }
  },
  computed: {
    titleCom() {
      return this.material.title
    },
    sendTimes2() {
      return this.material.sendTimes === null ? 0 : this.material.sendTimes
    }
  },
  mounted() {},
  methods: {
    toPup(par) {
      this.showPopup = true
    },
    closePup() {
      const videoElement = document.getElementById('video')
      console.log(videoElement)
      videoElement?.pause()
    },
    // 点击复选框
    checkboxFun(par) {
      console.log(par)
    },
    // 常用
    setCommon(params, index) {
      params.checkIndex = index
    },
    showActionSheet(params) {
    },
    // 详情
    toDetail(params) {
      const token = sessionStorage.getItem('token') || ''
      const shareObj = {
        title: params?.title,
        desc: params.description || params?.title,
        // link: params.detailUrl + '?businessId=' + token,
        link:
          params.detailUrl +
          (params.detailUrl.indexOf('?') === -1 ? '?' : '&') +
          'share=1&businessId=' +
          token,
        imgUrl: params.shareIconUrl
      }
      const data = {
        activity_sharerid: token,
        activity_id: params.contentId,
        activity_name: params?.title,
        activity_group: params.groupId,
        activity_group_name: params.groupName,
        activity_source: params.orgId
      }
      this.$sendClick('ViewActivityB', data)
      sessionStorage.setItem('material-share', JSON.stringify(shareObj))
      this.$router.push({
        path: '/material/detail',
        query: {
          src: encodeURIComponent(shareObj.link),
          type: 'activity',
          itemObj: params
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.item {
  /deep/ .van-popup {
    background-color: transparent;
  }
  margin-top: 10px;
  margin-left: 10px;
  .item-box {
    position: relative;
    width: 100%;
    height: 195px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
    /deep/ .van-checkbox {
      left: 10px;
      position: absolute;
      top: 10px;
    }
    /deep/ .van-checkbox__label {
      width: 92%;
    }
    .bfBtn {
      position: absolute;
      top: 25%;
      left: 38%;
      z-index: 999;
    }
    .card-img {
      height: 118px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    .tag {
      font-size: 12px;
      margin-left: 8px;
      margin-top: 0px;
      .tag2 {
        background-color: #e99d42;
        height: 20px;
        border-radius: 10%;
      }
    }
  }
  .line-height {
    line-height: 53px;
    padding: 0 8px !important;
  }
  .bom {
    display: flex;
    width: 100%;
    padding: 8px 0 8px 8px;
    box-sizing: border-box;
    align-items: center;
    .two {
      width: 85%;
      .title {
        font-size: 14px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
    .box1 {
      width: 15%;
      color: #999999;
      display: flex;
      align-items: center;
      justify-content: center;
      .title {
        font-size: 14px;
      }
      &.activeColor {
        color: #287de1;
      }
    }
  }
}
</style>
